  img {
    height: 100px;
  }
  
  h1 {
    color: #F64E60;
  }
  
  .pagination {
    display: flex;
    justify-content: space-between;
    list-style: none;
    cursor: pointer;
  }
  
  .pagination a {
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #F64E60;
    color: #F64E60;
  }
  
  .pagination__link {
    font-weight: bold;
  }
  
  .pagination__link--active a {
    color: #fff;
    background: #F64E60;
  }
  
  .pagination__link--disabled a {
    color: rgb(198, 197, 202);
    border: 1px solid rgb(198, 197, 202);
  }