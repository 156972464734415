.classeInputImage {
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    opacity: 1e-05;
    pointer-events: none;
    background-color: red !important;
}

.pac-container {
    z-index: 1100 !important;
}